var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "basic-container",
        [
          _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.$t("Variety Segmentation"),
                visible: _vm.dialogVisible,
                fullscreen: "",
                width: "70%",
                "before-close": _vm.handleClose
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogVisible = $event
                }
              }
            },
            [
              _c(
                "avue-crud",
                {
                  ref: "crud",
                  attrs: { option: _vm.tableOptions, data: _vm.tableDatas },
                  scopedSlots: _vm._u([
                    {
                      key: "menu",
                      fn: function(scope) {
                        return [
                          scope.row.status == 0
                            ? _c("div", { staticStyle: { height: "30px" } })
                            : _vm._e(),
                          _c(
                            "el-button",
                            {
                              staticClass: "none-border",
                              attrs: { type: "text", size: "small", plain: "" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.del(scope.row, scope.index)
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("DELETE")) + "\n          ")]
                          )
                        ]
                      }
                    }
                  ])
                },
                [
                  _c(
                    "template",
                    { slot: "menuRight" },
                    [
                      _c(
                        "el-col",
                        {
                          staticStyle: {
                            "text-align": "right",
                            "align-self": "flex-end"
                          },
                          attrs: { xs: 24, sm: 6, md: 8, lg: 8, xl: 4 }
                        },
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: { "margin-right": "0px" },
                              attrs: { type: "primary" },
                              on: { click: _vm.addRecord }
                            },
                            [_vm._v("添加记录\n            ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                2
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.dialogVisible = false
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("Cancel")))]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              staticStyle: { marginTop: "50px" },
              attrs: {
                option: _vm.tableOption,
                data: _vm.tableData,
                title: _vm.$t("Add record"),
                width: "60%",
                "append-to-body": true,
                visible: _vm.dialogVisible2,
                "before-close": _vm.handleClose1
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogVisible2 = $event
                }
              }
            },
            [
              _c(
                "div",
                {},
                [
                  _c(
                    "el-form",
                    {
                      ref: "productForm",
                      attrs: { model: _vm.productForm, "label-width": "120px" }
                    },
                    [
                      _c("el-form-item", [
                        _c(
                          "div",
                          { staticClass: "demo-input-suffix" },
                          [
                            _c(
                              "div",
                              { staticClass: "demo-input-suffix" },
                              [
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticStyle: { display: "flex" } },
                                    [
                                      _c("div", { staticClass: "label" }, [
                                        _vm._v("开始时间")
                                      ]),
                                      _c("el-date-picker", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          type: "datetime",
                                          "value-format": "yyyy-MM-dd HH:mm:ss",
                                          placeholder: "开始时间"
                                        },
                                        on: { change: _vm.getYield },
                                        model: {
                                          value: _vm.productForm.startTime,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.productForm,
                                              "startTime",
                                              $$v
                                            )
                                          },
                                          expression: "productForm.startTime"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ])
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { staticClass: "line", attrs: { span: 2 } },
                              [_vm._v("  ")]
                            ),
                            _c(
                              "div",
                              { staticClass: "demo-input-suffix" },
                              [
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticStyle: { display: "flex" } },
                                    [
                                      _c("div", { staticClass: "label" }, [
                                        _vm._v("设备")
                                      ]),
                                      _c("el-input", {
                                        staticStyle: { width: "100%" },
                                        attrs: { placeholder: "设备" },
                                        model: {
                                          value: _vm.productForm.machineCode,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.productForm,
                                              "machineCode",
                                              $$v
                                            )
                                          },
                                          expression: "productForm.machineCode"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]),
                      _c("el-form-item", [
                        _c(
                          "div",
                          { staticClass: "demo-input-suffix" },
                          [
                            _c(
                              "div",
                              { staticClass: "demo-input-suffix" },
                              [
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticStyle: { display: "flex" } },
                                    [
                                      _c("div", { staticClass: "label" }, [
                                        _vm._v("结束时间")
                                      ]),
                                      _c("el-date-picker", {
                                        attrs: {
                                          "value-format": "yyyy-MM-dd HH:mm:ss",
                                          type: "datetime",
                                          placeholder: "结束时间"
                                        },
                                        on: { change: _vm.getYield },
                                        model: {
                                          value: _vm.productForm.endTime,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.productForm,
                                              "endTime",
                                              $$v
                                            )
                                          },
                                          expression: "productForm.endTime"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ])
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { staticClass: "line", attrs: { span: 2 } },
                              [_vm._v("  ")]
                            ),
                            _c(
                              "div",
                              { staticClass: "demo-input-suffix" },
                              [
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticStyle: { display: "flex" } },
                                    [
                                      _c("div", { staticClass: "label" }, [
                                        _vm._v("品种名称")
                                      ]),
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            filterable: "",
                                            clearable: "",
                                            placeholder: "替换品种"
                                          },
                                          on: { change: _vm.dpinzhong },
                                          model: {
                                            value: _vm.proIds,
                                            callback: function($$v) {
                                              _vm.proIds = $$v
                                            },
                                            expression: "proIds"
                                          }
                                        },
                                        _vm._l(_vm.refineList, function(item) {
                                          return _c("el-option", {
                                            key: item.id,
                                            attrs: {
                                              label: item.name,
                                              value: item.id
                                            }
                                          })
                                        }),
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]),
                      _c("el-form-item", [
                        _c(
                          "div",
                          { staticClass: "demo-input-suffix" },
                          [
                            _c(
                              "div",
                              { staticClass: "demo-input-suffix" },
                              [
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticStyle: { display: "flex" } },
                                    [
                                      _c("div", { staticClass: "label" }, [
                                        _vm._v("开始设备米数")
                                      ]),
                                      _c("el-input", {
                                        attrs: {
                                          type: "number",
                                          placeholder: "开始设备米数"
                                        },
                                        on: { change: _vm.getYield },
                                        model: {
                                          value: _vm.productForm.startIotNumber,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.productForm,
                                              "startIotNumber",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "productForm.startIotNumber"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ])
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { staticClass: "line", attrs: { span: 2 } },
                              [_vm._v("  ")]
                            ),
                            _c(
                              "div",
                              { staticClass: "demo-input-suffix" },
                              [
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticStyle: { display: "flex" } },
                                    [
                                      _c("div", { staticClass: "label" }, [
                                        _vm._v("批号")
                                      ]),
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            filterable: "",
                                            clearable: "",
                                            placeholder: "替换批号"
                                          },
                                          on: { change: _vm.dpihao },
                                          model: {
                                            value: _vm.postIds,
                                            callback: function($$v) {
                                              _vm.postIds = $$v
                                            },
                                            expression: "postIds"
                                          }
                                        },
                                        _vm._l(_vm.workList, function(item) {
                                          return _c("el-option", {
                                            key: item.id,
                                            attrs: {
                                              label: item.batchCode,
                                              value: item.id
                                            }
                                          })
                                        }),
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]),
                      _c("el-form-item", [
                        _c(
                          "div",
                          { staticClass: "demo-input-suffix" },
                          [
                            _c(
                              "div",
                              { staticClass: "demo-input-suffix" },
                              [
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticStyle: { display: "flex" } },
                                    [
                                      _c("div", { staticClass: "label" }, [
                                        _vm._v("结束设备米数")
                                      ]),
                                      _c("el-input", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          type: "number",
                                          placeholder: "结束设备米数"
                                        },
                                        on: { change: _vm.getYield },
                                        model: {
                                          value: _vm.productForm.endIotNumber,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.productForm,
                                              "endIotNumber",
                                              $$v
                                            )
                                          },
                                          expression: "productForm.endIotNumber"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ])
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { staticClass: "line", attrs: { span: 2 } },
                              [_vm._v("  ")]
                            ),
                            _c(
                              "div",
                              { staticClass: "demo-input-suffix" },
                              [
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticStyle: { display: "flex" } },
                                    [
                                      _c("div", { staticClass: "label" }, [
                                        _vm._v("预估产量")
                                      ]),
                                      _c("el-input", {
                                        staticStyle: { width: "100%" },
                                        attrs: { laceholder: "预估产量" },
                                        model: {
                                          value: _vm.productForm.yield,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.productForm,
                                              "yield",
                                              $$v
                                            )
                                          },
                                          expression: "productForm.yield"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.dialogVisible2 = false
                        }
                      }
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.sub()
                        }
                      }
                    },
                    [_vm._v("确 定")]
                  )
                ],
                1
              )
            ]
          ),
          _c(
            "avue-crud",
            {
              ref: "crud",
              attrs: {
                option: _vm.tableOption,
                data: _vm.tableData,
                "table-loading": _vm.tableLoading,
                page: _vm.page
              },
              on: {
                "update:page": function($event) {
                  _vm.page = $event
                },
                "current-change": _vm.currentChange,
                "size-change": _vm.sizeChange,
                "refresh-change": _vm.refreshChange,
                "row-update": _vm.handleUpdate
              },
              scopedSlots: _vm._u([
                {
                  key: "menu",
                  fn: function(scope) {
                    return [
                      _c(
                        "el-button",
                        {
                          staticClass: "none-border",
                          attrs: { type: "text", size: "small", plain: "" },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.handleView(scope.row, scope.index)
                            }
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("Variety Segmentation")) +
                              "\n        "
                          )
                        ]
                      )
                    ]
                  }
                }
              ])
            },
            [
              _c(
                "template",
                { slot: "menuRight" },
                [
                  _c(
                    "el-form",
                    {
                      staticClass: "demo-form-inline",
                      staticStyle: { display: "flex" },
                      attrs: {
                        inline: true,
                        model: _vm.formInline,
                        labelWidth: "90px"
                      }
                    },
                    [
                      _c("el-date-picker", {
                        staticStyle: {
                          width: "150px !important",
                          "margin-right": "5px"
                        },
                        attrs: {
                          clearable: "",
                          size: "small",
                          type: "datetime",
                          placeholder: "开始日期"
                        },
                        model: {
                          value: _vm.startTime,
                          callback: function($$v) {
                            _vm.startTime = $$v
                          },
                          expression: "startTime"
                        }
                      }),
                      _c("el-date-picker", {
                        staticStyle: {
                          width: "150px !important",
                          "margin-right": "5px"
                        },
                        attrs: {
                          clearable: "",
                          size: "small",
                          type: "datetime",
                          placeholder: "结束日期"
                        },
                        model: {
                          value: _vm.endTime,
                          callback: function($$v) {
                            _vm.endTime = $$v
                          },
                          expression: "endTime"
                        }
                      }),
                      _c(
                        "el-select",
                        {
                          staticStyle: {
                            width: "100px !important",
                            marginRight: "5px"
                          },
                          attrs: {
                            filterable: "",
                            clearable: "",
                            placeholder: "品种",
                            size: "small"
                          },
                          on: { change: _vm.pinzhong },
                          model: {
                            value: _vm.processId,
                            callback: function($$v) {
                              _vm.processId = $$v
                            },
                            expression: "processId"
                          }
                        },
                        _vm._l(_vm.refineList, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id }
                          })
                        }),
                        1
                      ),
                      _c("el-autocomplete", {
                        staticStyle: {
                          width: "210px !important",
                          marginRight: "5px"
                        },
                        attrs: {
                          size: "small",
                          "fetch-suggestions": _vm.querySearchAsync,
                          placeholder: "请输入批号"
                        },
                        on: {
                          input: _vm.changeInput,
                          select: _vm.handleSelect
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                _c("div", { staticClass: "name" }, [
                                  _vm._v(_vm._s((item.value = item.batchCode)))
                                ])
                              ]
                            }
                          }
                        ]),
                        model: {
                          value: _vm.postId,
                          callback: function($$v) {
                            _vm.postId = $$v
                          },
                          expression: "postId"
                        }
                      }),
                      _c(
                        "el-select",
                        {
                          staticStyle: {
                            width: "100px !important",
                            marginRight: "5px"
                          },
                          attrs: {
                            filterable: "",
                            clearable: "",
                            placeholder: "车间",
                            size: "small"
                          },
                          on: { change: _vm.chejian },
                          model: {
                            value: _vm.workShopId,
                            callback: function($$v) {
                              _vm.workShopId = $$v
                            },
                            expression: "workShopId"
                          }
                        },
                        _vm._l(_vm.workshopList, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id }
                          })
                        }),
                        1
                      ),
                      _c(
                        "el-select",
                        {
                          staticStyle: {
                            width: "100px !important",
                            marginRight: "5px"
                          },
                          attrs: {
                            filterable: "",
                            clearable: "",
                            placeholder: "设备",
                            size: "small"
                          },
                          model: {
                            value: _vm.groupId,
                            callback: function($$v) {
                              _vm.groupId = $$v
                            },
                            expression: "groupId"
                          }
                        },
                        _vm._l(_vm.quipmentList, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id }
                          })
                        }),
                        1
                      ),
                      _c(
                        "el-button",
                        {
                          staticStyle: { height: "32px", "padding-top": "8px" },
                          attrs: { type: "primary", size: "mini" },
                          on: { click: _vm.search }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("search")) +
                              "\n          "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }