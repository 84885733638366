<template>
  <div>
    <basic-container>
      <el-dialog
          :title='$t("Variety Segmentation")'
          :visible.sync="dialogVisible"
          fullscreen
          width="70%"
          :before-close="handleClose">
        <avue-crud :option="tableOptions" :data="tableDatas" ref="crud">
          <template slot-scope="scope" slot="menu">
            <div style="height:30px;" v-if="scope.row.status == 0"/>
            <el-button type="text"
                       size="small"
                       plain
                       class="none-border"
                       @click.stop="del(scope.row,scope.index)">{{ $t("DELETE") }}
            </el-button>
          </template>
          <!-- <template slot="machineCode" slot-scope="{row}">
            <el-link style="color: #409EFF;text-style: none;" @click="clickLink(row)">
            </el-link>
          </template> -->
          <template slot="menuRight">
            <el-col :xs="24" :sm="6" :md="8" :lg="8" :xl="4" style="text-align: right; align-self: flex-end;">
              <!--                    <el-select v-model="value" placeholder="请选择班制">-->
              <!--                      <el-option-->
              <!--                          v-for="(item, index) in shiftOptions"-->
              <!--                          :key="index"-->
              <!--                          :label="item.name"-->
              <!--                          :value="item.id">-->
              <!--                      </el-option>-->
              <!--                    </el-select>-->
              <el-button type="primary" style="margin-right:0px;" @click="addRecord">添加记录
              </el-button>
            </el-col>
          </template>
        </avue-crud>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">{{ $t("Cancel") }}</el-button>
          <!--          <el-button type="primary" @click="sub">{{$t("Save")}}</el-button>-->
        </span>
      </el-dialog>
      <el-dialog
          :option="tableOption"
          :data="tableData"
          :title='$t("Add record")'
          width="60%"
          style="marginTop:50px;"
          :append-to-body="true"
          :visible.sync="dialogVisible2"
          :before-close="handleClose1">
        <div class="">
          <el-form ref="productForm" :model="productForm" label-width="120px">
            <el-form-item>
              <div class="demo-input-suffix">
                <div class="demo-input-suffix">
                  <el-col :span="8">
                    <div style="display: flex">
                      <div style="" class="label">开始时间</div>
                      <el-date-picker @change="getYield" style="width: 100%;" type="datetime"
                                      value-format="yyyy-MM-dd HH:mm:ss"
                                      placeholder="开始时间" v-model="productForm.startTime"></el-date-picker>
                    </div>
                  </el-col>
                </div>
                <el-col class="line" :span="2">&nbsp;&nbsp;</el-col>
                <div class="demo-input-suffix">
                  <el-col :span="8">
                    <div style="display: flex">
                      <div style="" class="label">设备</div>
                      <el-input style="width: 100%;" v-model="productForm.machineCode" placeholder="设备"></el-input>
                    </div>
                  </el-col>
                </div>
              </div>
            </el-form-item>
            <el-form-item>
              <div class="demo-input-suffix">
                <div class="demo-input-suffix">
                  <el-col :span="8">
                    <div style="display: flex">
                      <div style="" class="label">结束时间</div>
                      <el-date-picker @change="getYield" value-format="yyyy-MM-dd HH:mm:ss" type="datetime"
                                      placeholder="结束时间"
                                      v-model="productForm.endTime"></el-date-picker>
                    </div>
                  </el-col>
                </div>
                <el-col class="line" :span="2">&nbsp;&nbsp;</el-col>
                <div class="demo-input-suffix">
                  <el-col :span="8">
                    <div style="display: flex">
                      <div style="" class="label">品种名称</div>
                      <el-select filterable clearable v-model="proIds" @change="dpinzhong" placeholder="替换品种">
                        <el-option v-for="item in refineList" :key="item.id" :label="item.name"
                                   :value="item.id"></el-option>
                      </el-select>
                    </div>
                  </el-col>
                </div>
              </div>
            </el-form-item>
            <el-form-item>
              <div class="demo-input-suffix">
                <div class="demo-input-suffix">
                  <el-col :span="8">
                    <div style="display: flex">
                      <div style="" class="label">开始设备米数</div>
                      <el-input type="number" @change="getYield" placeholder="开始设备米数"
                                v-model="productForm.startIotNumber"></el-input>
                    </div>
                  </el-col>
                </div>
                <el-col class="line" :span="2">&nbsp;&nbsp;</el-col>
                <div class="demo-input-suffix">
                  <el-col :span="8">
                    <div style="display: flex">
                      <div style="" class="label">批号</div>
                      <el-select filterable clearable v-model="postIds" @change="dpihao" placeholder="替换批号">
                        <el-option v-for="item in workList" :key="item.id" :label="item.batchCode"
                                   :value="item.id"></el-option>
                      </el-select>
                    </div>
                  </el-col>
                </div>
              </div>
            </el-form-item>
            <el-form-item>
              <div class="demo-input-suffix">
                <div class="demo-input-suffix">
                  <el-col :span="8">
                    <div style="display: flex">
                      <div style="" class="label">结束设备米数</div>
                      <el-input type="number" @change="getYield" placeholder="结束设备米数" style="width: 100%;"
                                v-model="productForm.endIotNumber"></el-input>
                    </div>
                  </el-col>
                </div>
                <el-col class="line" :span="2">&nbsp;&nbsp;</el-col>
                <div class="demo-input-suffix">
                  <el-col :span="8">
                    <div style="display: flex">
                      <div style="" class="label">预估产量</div>
                      <el-input v-model="productForm.yield" laceholder="预估产量" style="width: 100%;"></el-input>
                    </div>
                  </el-col>
                </div>
              </div>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible2 = false">取 消</el-button>
    <el-button type="primary" @click="sub()">确 定</el-button>
  </span>
      </el-dialog>
      <avue-crud :option="tableOption"
                 :data="tableData"
                 :table-loading="tableLoading"
                 :page.sync="page"
                 ref="crud"
                 @current-change="currentChange"
                 @size-change="sizeChange"
                 @refresh-change="refreshChange"
                 @row-update="handleUpdate"
      >

        <template slot-scope="scope" slot="menu">
          <!-- <div  style="height:30px;" v-if="scope.row.status == 0"/> -->
          <el-button type="text"
                     size="small"
                     plain
                     class="none-border"
                     @click.stop="handleView(scope.row,scope.index)">{{ $t("Variety Segmentation") }}
          </el-button>
        </template>
        <!-- <template slot="machineCode" slot-scope="{row}">
          <el-link style="color: #409EFF;text-style: none;" @click="clickLink(row)">
          </el-link>
        </template> -->

        <template slot="menuRight">
          <el-form :inline="true" :model="formInline" class="demo-form-inline" labelWidth="90px"
                   style="display:flex;">
            <!-- <el-form-item label="开始时间:"  style="width:250px;display:flex;"> -->
            <el-date-picker clearable
                            v-model="startTime"
                            size="small"
                            type="datetime"
                            placeholder="开始日期" style="width:150px !important;margin-right: 5px;">
            </el-date-picker>

            <!-- </el-form-item>
             <el-form-item label="结束时间:"  style="width:250px;display:flex;height:32px"> -->
            <!--                      value-format="yyyy-MM-dd"-->
            <el-date-picker clearable
                            v-model="endTime"
                            size="small"
                            type="datetime"
                            placeholder="结束日期" style="width:150px !important;margin-right: 5px;">
            </el-date-picker>
            <!-- </el-form-item> -->
            <el-select filterable clearable v-model="processId" @change="pinzhong" placeholder="品种"
                       size="small" style="width:100px !important;marginRight: 5px;">
              <el-option v-for="item in refineList" :key="item.id" :label="item.name"
                         :value="item.id"></el-option>
            </el-select>
            <!-- <el-select filterable clearable v-model="postId" placeholder="批号" size="small" style="width:100px !important;marginRight: 5px;">
              <el-option v-for="item in workLists" :key="item.id" :label="item.batchCode" :value="item.id"></el-option>
            </el-select> -->
            <el-autocomplete
                size="small" style="width:210px !important;marginRight: 5px;"
                v-model="postId"
                :fetch-suggestions="querySearchAsync"
                @input="changeInput"
                placeholder="请输入批号"
                @select="handleSelect">
              <template slot-scope="{item}">
                <div class="name">{{ item.value = item.batchCode }}</div>
              </template>
            </el-autocomplete>
            <el-select filterable clearable v-model="workShopId" @change="chejian" placeholder="车间"
                       size="small" style="width:100px !important;marginRight: 5px;">
              <el-option v-for="item in workshopList" :key="item.id" :label="item.name"
                         :value="item.id"></el-option>
            </el-select>
            <el-select filterable clearable v-model="groupId" placeholder="设备" size="small"
                       style="width:100px !important;marginRight: 5px;">
              <el-option v-for="item in quipmentList" :key="item.id" :label="item.name"
                         :value="item.id"></el-option>
            </el-select>
            <el-button type="primary" @click="search" size="mini" style="height:32px;padding-top: 8px;">
              {{ $t("search") }}
            </el-button>
          </el-form>
        </template>
      </avue-crud>
    </basic-container>
  </div>
</template>
<script>
import {
  searchVarieties,
  searchNumber,
  searcheQuipment,
  list,
  searchWorkshop,
  getCommmit,
  getvarietiesById,
  getBatchById,
  getIdByCode
} from "@/api/refineStyle";
import {dateFormat} from '@/filters'

export default {
  data() {
    return {
      // 弹出层
      getRow: {},
      table: [],
      varieties: '',
      batch: '',
      batchCodeId: '',
      batchCodeValue: '',
      batchCodeOptions: '',
      productValue: '',
      productOptions: [],
      originStartTime: '',
      originEndTime: '',
      productForm: {
        machineCode: '',
        startIotNumber: '',
        endIotNumber: '',
        yield: ''
      },
      tableDatas: [],
      tableOptions: {
        editBtn: false,
        delBtn: false,
        refreshBtn: false,
        addBtn: false,
        menuWidth: 200,
        columnBtn: false,
        emptyText: this.$t('No Data'),
        menuTitle: this.$t('OPERATION'),
        column: [{
          label: "品种名称",
          // slot: true,
          prop: "productName",
          id: 'ids'
        }, {
          label: "设备名称",
          prop: "machineName",

        }, {
          label: "批号",
          prop: "batchCode"
        }, {
          label: "开始时间",
          prop: "startTime"
        }, {
          label: "结束时间",
          prop: "endTime"
        }, {
          label: "开始设备米数",
          prop: "startIotLength"
        }, {
          label: "结束设备米数",
          prop: "endIotLength"
        }, {
          label: "预估产量",
          prop: "yield"
        }
        ]
      },
      formInline: {},
      formInlines: {},
      equipments: '', //设备名称
      machineId: '',
      startTimes: '',
      endTimes: '',
      outputs: '',
      consumptions: '',
      // pageSize: 10,
      // pagerCount: 50,
      // total: 10,
      // pageNo: 1,
      // workShopId:'',
      // groupId:'', // 设备
      proIds: '', //品种
      postIds: '', //批号
      // 其他------
      equipment: '', //设备名称
      output: '', //产量
      consumption: '', //能耗
      //  细纱
      refineList: [],//品种列表
      quipmentList: [],//设备列表
      startTime: null, //开始时间
      endTime: null, //结束时间
      pinzhongId: null, //品种Id
      // valueTransfer: [],
      tableLoading: false,
      workshopList: [], //车间列表
      // classData:null,
      // dataTransfer: [],
      // //添加机台
      // addVisible: false,
      // workValue: "",
      // wklnValue: "",
      // postName:'',
      // processName:'',
      // refrechValue: "",
      // calssValue: "",
      // //班组
      // refrechLists: [],
      // //工序
      // wklnLists: [],
      // //车间
      // calssLists: [],
      // //岗位
      workLists: [],
      workList: [],
      // //当前编辑的人员
      // userId: "",
      // //机台
      // nameorcode: "",
      // classAssign: "甲班",
      // wkshp: "主车间",
      // person: "人物",
      dialogVisible: false,
      dialogVisible2: false,
      page: {
        pagerCount: 5,
        total: 0,
        pageCurrent: 1,
        pageSize: 20,
        // pagerCount: 50,
        // pageNo: 1,

      },
      workShopId: '',
      groupId: '', // 设备
      processId: '', //品种
      postId: '', //批号
      total: 0,
      input: "",
      tableData: [],
      tableOption: {
        editBtn: false,
        delBtn: false,
        refreshBtn: false,
        addBtn: false,
        menuWidth: 200,
        columnBtn: false,
        // dialogFullscreen: true,
        // addBtnText: this.$t('ADD'),
        // editBtnText: this.$t('Edit'),
        emptyText: this.$t('No Data'),
        menuTitle: this.$t('OPERATION'),
        column: [{
          label: "品种名称",
          //   slot: true,
          prop: "productName"
        }, {
          label: "设备名称",
          prop: "machineName"
        }, {
          label: "批号",
          prop: "batchCode"
        }, {
          label: "开始时间",
          prop: "startTime"
        }, {
          label: "结束时间",
          prop: "endTime"
        }, {
          label: "产量(kg)",
          prop: "yield"
        }, {
          label: "能耗(kwh)",
          prop: "energy"
        }]
      }
    }
  },
  created() {
    list().then(res => {
      console.log('哈哈哈', res);
      // this.tableData = res.data.data.data
    })
    // 查询品种
    searchVarieties().then(res => {
      console.log('细沙列表', res);
      this.refineList = res.data.data.items
    }),
        // 查询车间
        searchWorkshop(1).then(res => {
          console.log('车间', res.data);
          this.workshopList = res.data.data
        });
    this.getTimeFn();
    this.search()
  },
  mounted() {
    //列表
    // list(this.postId,this.startTime,this.endTime,this.processId,this.postId,this.page.pageNo,this.page.pageSize).then(res => {
    //      console.log('=======',res,this.postId);
    // })
    //查询批号
    // searchNumber(this.refineList[0].id).then(res => {
    //     console.log('批号',res);
    // })
  },
  methods: {
    async getYield() {
      if (!this.proIds) {
        this.$message.error('Please select a variety')
        return
      }
      const res = await this.request.get(this.proApis.CALCULATEPRODUCTION + "?machineId=" + this.machineId + '&strTime=' + this.productForm.startTime + '&strIotNumber=' + this.productForm.startIotNumber + '&endTime=' + this.productForm.endTime + '&endIotNumber=' + this.productForm.endIotNumber + '&productId=' + this.proIds)
      this.productForm.yield = res.data.data
    },
    addRecord() {
      this.productValue = this.productOptions[0].id;
      this.dialogVisible2 = true;
    },
    changeInput() {
      searchNumber(this.processId, this.postId).then(res => {
        console.log('批号', res);
        this.workLists = res.data.data
        // this.workLists.slice(0,20)
      })
    },
    querySearchAsync(queryString, cb) {
      var restaurants = this.workLists;
      console.log(restaurants);
      var result = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
      cb(result);
    },
    createFilter(queryString) {
      return (restaurant) => {
        if (restaurant.batchCode !== null) {
          return (restaurant.batchCode.toLowerCase().indexOf(queryString.toLowerCase()) !== -1);
        }

      };
    },
    handleSelect(item) {
      console.log(item);
      this.batchCodeId = item.id
      console.log(item.id);

    },
    dpinzhong(e) {
      this.postIds = ''
      searchNumber(e).then(res => {
        console.log('dialog批号', res);
        this.workList = res.data.data;
      })
      getvarietiesById(e).then(res => {
        console.log(res, 'mingzi');
        this.varieties = res.data.data.name
      })
      this.getYield();
      console.log('val', e);
    },
    dpihao(val) {
      getBatchById(val).then(res => {
        this.batch = res.data.data.batchCode
        console.log('id', res);
      })
      //this.getYield();
    },
    handleClose(done) {
      this.$confirm(this.$t("IsClose"))
          .then(_ => {
            done();
          })
          .catch(_ => {
          });
    },
    handleClose1(done) {
      this.dialogVisible2 = false;
    },
    pinzhong(val) {
      if (this.processId == '') {
        return
      } else {
        // searchNumber(val,'11').then(res => {
        //   console.log('批号',res);
        //   this.workLists = res.data.data
        //   this.workLists.slice(0,20)
        // })
      }
      // this.pinzhongId = val;
    },
    addConfirm() {

    },
    cjChange() {

    },
    chejian(val) {
      console.log('val', val);
      if (this.workShopId == '') {
        return
      } else {
        // 查询设备
        searcheQuipment(val).then(res => {
          console.log('设备', res.data);
          this.quipmentList = res.data.data.items
        })
      }
    },

    async search() {
      if (this.postId != '') {
        await getIdByCode(this.postId).then(res => {
          console.log(res);
          this.batchCodeId = res.data.data.id
          console.log(res.data.data.id);
          console.log(this.batchCodeId);
        })
      }

      if (this.startTime == '' || this.endTime == '') {
        console.log('输入时间');
      } else {
        await list(this.page.pageCurrent, this.page.pageSize, dateFormat(new Date(this.startTime)), dateFormat(new Date(this.endTime)), this.groupId, this.processId, this.batchCodeId).then(res => {
          console.log('=======', res);
          if (res.data.data == null) {
            this.tableData = [];
            this.page.total = 0;
          } else {
            this.tableData = res.data.data.data;
            this.page.total = res.data.data.total;
          }
          console.log(this.page);
        })
      }
    },
    handleUpdate() {

    },
    refreshChange() {
      this.search();
    },
    //点击修改
    handleView(row) {
      this.getRow = row
      this.proIds = row.productid;
      this.postIds = row.batchCode;
      this.originStartTime = this.getRow.startTime;
      this.originEndTime = this.getRow.endTime;
      console.log(this.getRow);
      this.dialogVisible = true
      this.equipments = row.machineName
      this.machineId = row.machineId
      let productObj = this.request({
        url: this.businessUrl + "product/selectAll",
        method: "get",
        data: {}
      });
      productObj.then(res => {
        res.data.data = res.data.data.filter(v => {
          return v.enableBatch == 1;
        });
        this.productOptions = res.data.data;
      });
      this.productMachineData();
      this.productForm = row;
      this.$set(this.productForm, "machineCode", row.machineName);
      this.$set(this.productForm, "startIotNumber", row.startIotLength);
      this.$set(this.productForm, "endIotNumber", row.endIotLength);
      // this.productForm.machineCode = row.machineName;
      // this.productForm.startIotNumber = row.startIotLength;
      // this.productForm.endIotNumber = row.endIotLength;
    },
    //弹出框的删除
    del(row) {
      console.log(row);
      this.$confirm(this.$t('IsDel'), this.$t('Tips'), {
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('Cancel'),
        type: 'warning'
      }).then(() => {
        console.log('row', row);
        console.log('this.tableDatas', this.tableDatas);
        this.tableDatas.splice(row, 1)
        // this.list(this.item);
        this.$message({
          showClose: true,
          message: "删除成功",
          type: "success"
        });
      })
          .catch(() => {
          });
    },
    // 弹出框的保存
    save() {
      let objSave = {
        machineName: this.equipments,
        productName: this.varieties,
        proIds: this.proIds,
        postIds: this.postIds,
        outputs: this.outputs,
        consumptions: this.consumptions,
        batchCode: this.batch,
        startTime: dateFormat(new Date(this.startTimes)),
        endTime: dateFormat(new Date(this.endTimes)),
        yield: this.outputs,
        energy: this.consumptions
      }
      this.tableDatas.push(objSave)
    },
    // 弹出框的提交
    sub() {
      let subObj = {
        cutProductId: this.proIds, // 切点品种id
        cutBatchId: this.postIds,// 切点批号id
        cutStartTime: this.startTimes,// 切点时间
        cutEndTime: this.endTimes,// 切点结算时间
        cutYield: this.outputs,// 开始-切点品种 的产量
        cutEnergy: this.consumptions// 开始-切点品种 的能耗
      }
      // this.table.push(subObj);
      /*
       column: [{
                              label: "品种名称",
                              // slot: true,
                              prop: "productName",
                              id: 'ids'
                          }, {
                              label: "设备名称",
                              prop: "machineName",

                          }, {
                              label: "批号",
                              prop: "batchCode"
                          }, {
                              label: "开始时间",
                              prop: "startTime"
                          }, {
                              label: "结束时间",
                              prop: "endTime"
                          }, {
                              label: "产量(kg)",
                              prop: "yield"
                          }, {
                              label: "能耗(kwh)",
                              prop: "energy"
                          }]
       */
      // this.tableDatas.forEach((f) => {
      //   this.table.push({
      //     cutProductId: f.proIds,
      //     cutBatchId: f.postIds,
      //     cutStartTime: f.startTime,
      //     cutEndTime: f.endTime,
      //     cutYield: f.outputs,
      //     cutEnergy: f.consumptions
      //   })
      // })
      console.log('1314')
      this.table.push({
        cutProductId: this.proIds,
        cutBatchId: this.postIds,
        cutStartTime: this.productForm.startTime,
        cutEndTime: this.productForm.endTime,
        cutYield: this.productForm.yield,
        cutEnergy: this.productForm.yield,
        startIotLength: this.productForm.startIotNumber,
        endIotLength: this.productForm.endIotNumber
      })

      console.log(this.tableDatas, 'tableDatas')
      console.log(this.table, 'table');
      console.log(this.getRow, 'getRow');
      this.getRow.startTime = this.originStartTime;
      this.getRow.endTime = this.originEndTime;
      // return ''
      getCommmit(this.getRow, this.table).then(res => {
        list(this.page.pageCurrent, this.page.pageSize, this.startTime + ' 00:00:000', this.endTime + ' 00:00:000', this.groupId, this.processId, this.postId).then(res => {
          this.tableData = res.data.data.data
        })
        if (res.data.code == "0000") {
          this.$message({
            type: "success",
            message: "添加成功",
          });
          console.log('res', res);
          this.dialogVisible = false
          this.dialogVisible2 = false
        }
      })
      console.log('弹出框的提交');
    },
    currentChange(pageCurrent) {
      this.page.pageCurrent = pageCurrent;
      this.search();
    },
    sizeChange(pageSize) {
      this.page.pageCurrent = 1;
      this.page.pageSize = pageSize;
      this.search();
    },
    getTimeFn() {
      console.log(1);
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
      this.startTime = this.formatDate(start);
      this.endTime = this.formatDate(end);
    },
    formatDate(date) {
      var myyear = date.getFullYear();
      var mymonth = date.getMonth() + 1;
      var myweekday = date.getDate();

      if (mymonth < 10) {
        mymonth = "0" + mymonth;
      }
      if (myweekday < 10) {
        myweekday = "0" + myweekday;
      }
      return myyear + "-" + mymonth + "-" + myweekday;
    },
    productMachineData() {
      this.productMachineDataOptions();
    },
    async productMachineDataOptions() {
      let obj = {
        "batchid": this.batchCodeId,
        "endTime": dateFormat(new Date(this.endTime)),
        "machineId": this.machineId,
        "pageNo": 1,
        "pageSize": 20,
        "productid": this.processId,
        "startTime": dateFormat(new Date(this.startTime))
      }
      let res = await this.request.post(this.proApis.QUERYPRODUCTMACHINEDATA, obj)
      this.tableDatas = res.data.data;
    },
  },
  watch: {
    dialogVisible2() {
      // this.proIds = '';
      // this.postIds = ''
      this.productForm.yield = '';
      //this.$refs.productForm.resetFields()
    },
    dialogVisible(New) {
      if (!New) {
        this.$refs['ruleForm1'].resetFields();
        this.proIds = ''
        this.postIds = ''
        this.startTimes = ''
        this.endTimes = ''
        this.outputs = ''
        this.consumptions = ''
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.machineClass {
  /deep/ .avue-crud__left {
    width: 100%;
  }

  /deep/ .avue-crud__right {
    width: 100%;
  }
}

.label {
  white-space: nowrap;
  margin-right: 10px;
  width: 120px;
  text-align: right;
}
</style>
